// A door for each day with appropriate date
<template>
  <!-- <button
    @click="$emit('door-clicked', doorNumber)"
    v-bind:class="{
      'button--door': true,
      'button--past': doorNumber < today,
      'button--today': doorNumber === today,
      'button--future': doorNumber > today,
    }"
  >
  </button> -->
  <door-image
    :doorNumber="doorNumber"
    @door-clicked="$emit('door-clicked', doorNumber)"
  >
    <div>
      <div v-show="doorNumber % 10 === 1">Dec {{ doorNumber }}st</div>
      <div v-show="doorNumber % 10 === 2">Dec {{ doorNumber }}nd</div>
      <div v-show="doorNumber % 10 === 3">Dec {{ doorNumber }}rd</div>
      <div v-show="doorNumber % 10 > 3 || doorNumber % 10 === 0">
        Dec {{ doorNumber }}th
      </div>
    </div>
  </door-image>
</template>

<script>
import DoorImage from "./DoorImage.vue";

export default {
  props: ["doorNumber", "today"],
  emits: ["door-clicked"],
  components: { DoorImage },
};
</script>

<style scoped></style>
