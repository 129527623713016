import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBpYoTsCykAcvHdqyyzpiK2HqCL-_m42aU",
  authDomain: "vuetest-283fc.firebaseapp.com",
  databaseURL:
    "https://vuetest-283fc-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "vuetest-283fc",
  storageBucket: "vuetest-283fc.appspot.com",
  messagingSenderId: "539468817337",
  appId: "1:539468817337:web:f8c7492330d5bc5f0d0c68",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init firestore
// const projectFirestore = firebase.firestore();

// init firebase storage (for larger files)
const projectStorage = firebase.storage();

export { projectStorage };
