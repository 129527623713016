<template>
  <div>
    <the-header></the-header>
    <router-view></router-view>
  </div>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";

export default {
  components: {
    TheHeader,
  },
  data() {
    return {
      isAuth: false,
      userId: "",
      idToken: "",
    };
  },
  computed: {},
  methods: {
    toggleAuth() {
      console.log("In toggleAuth, before: " + this.isAuth);
      this.isAuth = !this.isAuth;
      console.log("In toggleAuth, after: " + this.isAuth);
    },
    getAuthState() {
      return this.isAuth;
    },
    setUserId(newUserId) {
      this.userId = newUserId;
    },
    tryLogin() {
      this.idToken = localStorage.getItem("idToken");
      this.userId = localStorage.getItem("userId");
      if (this.idToken && this.userId) {
        this.isAuth = true;
      }
    },
  },
  provide() {
    return {
      isAuth: this.isAuth,
      userId: this.userId,
      getAuthState: this.getAuthState,
      toggleAuth: this.toggleAuth,
      setUserId: this.setUserId,
    };
  },
  created() {
    this.tryLogin();
  },
};
</script>
