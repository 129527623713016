// Overlay when the day clicked is not the right one, image+music
<template>
  <dialog open>
    <img src="@/assets/nono.gif" alt="" />
    <br />
    Today is too... <slot> </slot>
    <br />
    <button @click.stop="closeOverlay">Back to the calendar</button>

    <audio :src="musicURL" autoplay>
      Your browser does not support the audio element.
    </audio>
  </dialog>
</template>

<script>
import { projectStorage } from "../firebase/config";

export default {
  inject: ["toggleDoor"],
  data() {
    return {
      musicURL: "",
      musicPath: "/music/nonono.mp3",
    };
  },

  methods: {
    closeOverlay() {
      this.toggleDoor();
    },
  },

  mounted() {
    const ref2 = projectStorage.ref(this.musicPath);
    console.log("The ref is " + ref2);
    // alternative from https://stackoverflow.com/questions/63590279/how-to-download-a-file-from-firebase-storage-in-a-vue-web-app
    ref2
      .getDownloadURL()
      .then((url) => {
        // `url` is the download URL
        console.log("The music-url is: " + url);
        this.musicURL = url;
      })
      .catch((error) => {
        console.log("Could not fetch url, an error occured: " + error.code);
        // Handle any errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;

          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
          default:
            break;
        }
      });
  },
};
</script>

<style scoped>
dialog {
  position: fixed;
}
</style>
