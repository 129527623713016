// Renders an individual door with an animated gif (when unopened)
<template>
  <div>
    <img
      :src="
        hoverState
          ? require('@/assets/' + imageSource[1])
          : require('@/assets/' + imageSource[0])
      "
      @mouseenter="hoverState = true"
      @mouseleave="hoverState = false"
      alt="Your door to daily inspiration"
      :class="hoverState ? 'daily-image--large' : 'daily-image--small'"
      @click="$emit('door-clicked', doorNumber)"
    />
    <!-- Slot should be filled with day -->
    <slot> Click me :) </slot>
  </div>
</template>

<script>
export default {
  props: ["doorNumber"],
  emits: ["door-clicked"],
  data() {
    return {
      hoverState: false,
      imageSource: ["door.jpg", "door_opening.gif"],
    };
  },
};
</script>

<style scoped>
.daily-image--large {
  max-height: 120px;
}

.daily-image--small {
  max-height: 120px;
  background-color: goldenrod;
}
</style>
