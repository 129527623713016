// Main calendar view
<template>
  <div>
    <revealed-content
      v-if="revealTheDoor && revealedDoorNb === today"
      :revealedDay="revealedDoorNb"
    ></revealed-content>

    <not-today v-if="revealTheDoor && revealedDoorNb < today">
      late
    </not-today>

    <not-today v-if="revealTheDoor && revealedDoorNb > today">
      early
    </not-today>

    <div>
      <h1>Welcome to the 2020 advent calendar!</h1>
      <p>
        What a crazy year! Click today's door and be surprised (past or future
        days' doors are not available)
      </p>

      <div :class="{ adventskalender: true, 'grey-out': greyOut }">
        <daily-door
          v-for="day in 24"
          :key="day"
          :door-number="day"
          @door-clicked="revealDoor(day)"
          id="daily-door"
        ></daily-door>
      </div>

      <!-- Snowflakes come here -->
      <!-- <div v-for="i in 200" :key="i">
        <div class="snow"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import DailyDoor from "../components/DailyDoor.vue";
import RevealedContent from "../components/RevealedContent.vue";
import NotToday from "../components/NotToday.vue";

export default {
  name: "App",
  components: {
    DailyDoor,
    RevealedContent,
    NotToday,
  },
  data() {
    return {
      revealTheDoor: false,
      greyOut: false,
      revealedDoorNb: null,
      today: 0,
    };
  },
  methods: {
    toggleDoor() {
      this.revealTheDoor = !this.revealTheDoor;
      this.greyOut = !this.greyOut;
    },
    revealDoor(doorNb) {
      var d = new Date();
      this.today = d.getDate();
      this.revealTheDoor = true;
      this.revealedDoorNb = doorNb;
      this.revealTheDoor = true;
      console.log("Revealing door " + doorNb);
    },
    toggleModal() {
      console.log("In toggle me, revealTheDoor = " + this.revealTheDoor);
      this.toggleDoor();
      // if (this.revealTheDoor === true) {
      //   console.log("calling toggleDoor");
      //   this.toggleDoor();
      // }
    },
  },
  provide() {
    return { toggleDoor: this.toggleDoor };
  },
};
</script>

<style>
@import url("http://fonts.googleapis.com/css?family=Raleway");

/* TODO –– Create overlay
https://www.w3schools.com/howto/howto_css_overlay.asp
*/

html {
  /* font-family: sans-serif; */
  font-family: "Raleway";
}

body {
  background-color: rgb(48, 30, 30);
  color: burlywood;
  text-align: center;
  margin: 0;
  height: 100%;
  /* overflow: hidden; */
}

body h1 {
  font-size: 3em;
}

.adventskalender {
  margin: 50px auto;
  justify-content: space-around;
  width: 90%;
  padding: 0 auto;
  display: grid;
  grid-template-columns: 0.1fr 0.1fr 0.1fr 0.1fr;
  grid-gap: 15px;
}

@media screen and (max-width: 900px) {
  .adventskalender {
    grid-template-columns: 0.1fr 0.1fr 0.1fr;
    /* background-color: purple; */
  }
}

.adventskalsender .grey-out {
  background-color: grey;
}

.cell {
  color: white;
  font-size: 3rem;
  text-align: center;
  padding: 4rem;
  border-color: white;
  border-width: 5px;
}

#daily-door {
  align-content: center;
}
</style>
