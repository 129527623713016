<template>
  <header>
    <!-- <span>Auth-state: {{ getAuthState() }}</span> -->

    <div v-if="getAuthState()">
      <button class="button-login" @click="logout">Logout again</button>
    </div>
    <div v-if="!getAuthState()">
      <button class="button-login" @click="secretLogin">
        <section>Secret Login &#x1f384;</section>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  inject: ["isAuth", "getAuthState", "toggleAuth"],
  methods: {
    secretLogin() {
      this.$router.push("/auth");
    },
    logout() {
      localStorage.removeItem("userId");
      localStorage.removeItem("idToken");
      this.toggleAuth();
      console.log("Toggling auth state, new state: " + this.getAuthState());
    },
  },
};
</script>

<style scoped>
header {
  width: 100%;
  height: 2rem;
  background-color: rgb(48, 30, 30);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-login {
  margin-right: 2vw;
  margin-top: 1vh;
  background-color: lightyellow;
  white-space: nowrap;
}
</style>
