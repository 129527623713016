// an overlay containing the content of the day (depending on login-state)
<template>
  <!-- semantic element dialog -->
  <dialog open class="revealed-overlay">
    <img :src="imageURL" alt="oh no - no image today!" class="daily-image" />
    <br />
    Enjoy your day ! <br />
    Only {{ 24 - revealedDay }} nights left until christmas eve! <br />
    <br />
    <button @click="closeOverlay">Back to calendar</button>

    <audio :src="musicURL" type="audio/mpeg" autoplay>
      Your browser does not support the audio element.
    </audio>
  </dialog>
</template>

<script>
import { projectStorage } from "../firebase/config";

export default {
  props: ["revealedDay"],
  inject: ["toggleDoor", "isAuth", "getAuthState"],
  data() {
    return {
      imageURL: "",
      musicURL: "",
      musicPath: "/music/success.mp3",
      imagePath: !this.getAuthState() ? "/doors/" : "/specialDoors/",
      imageType: !this.getAuthState() ? ".gif" : ".jpg",
      hover: false,
    };
  },
  methods: {
    closeOverlay() {
      this.toggleDoor();
    },
  },
  mounted() {
    /// Load image
    const imagePath = this.imagePath + this.revealedDay + this.imageType;
    const ref = projectStorage.ref(imagePath);

    console.log("The ref is " + ref);
    // ref.put(file);
    // alternative from https://stackoverflow.com/questions/63590279/how-to-download-a-file-from-firebase-storage-in-a-vue-web-app
    ref
      .getDownloadURL()
      .then((url) => {
        // `url` is the download URL
        console.log("The url is: " + url);
        this.imageURL = url;
      })
      .catch((error) => {
        console.log("Could not fetch url, an error occured: " + error.code);
        // Handle any errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;

          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
          default:
            break;
        }
      });

    // Load and play music
    const ref2 = projectStorage.ref(this.musicPath);

    console.log("The ref is " + ref2);
    // ref.put(file);
    // alternative from https://stackoverflow.com/questions/63590279/how-to-download-a-file-from-firebase-storage-in-a-vue-web-app
    ref2
      .getDownloadURL()
      .then((url) => {
        // `url` is the download URL
        console.log("The music-url is: " + url);
        this.musicURL = url;
      })
      .catch((error) => {
        console.log("Could not fetch url, an error occured: " + error.code);
        // Handle any errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;

          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
          default:
            break;
        }
      });
  },
};
</script>

<style scoped>
dialog {
  background: rgb(114, 100, 100);
  color: honeydew;
  position: fixed;
  max-width: 90vw;
  max-height: 90vh;
  /* max-height: 70%; */
}

img {
  height: 70vh;
  object-fit: scale-down;
  /* width: 90%; */
  /* max-height: 50%;
  max-width: 70%; */
}
</style>
