// Authentication of users (only login possible, no singup currently)
<template>
  <div>
    <form @submit.prevent="authenticate">
      <label for="email">email</label>
      <input type="email" v-model.trim="email" />

      <br />
      <label for="password">password</label>
      <input type="password" v-model.trim="password" />
      <br />
      <button>Submit</button>
    </form>

    <!-- This does not work yet -->
    <p>{{ loginStatus }}</p>
    <!-- From console – error. "this.getAuthState is not a function" -->
    <!-- <p v-if="getAuthState === true">
      Only visible if authenticated {{ userId }}
    </p> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      formIsValid: true,
      loginStatus: "Please log in",
    };
  },
  inject: ["isAuth", "userId", "toggleAuth", "setUserId", "getAuthState"],
  computed: {},
  methods: {
    async authenticate() {
      if (this.email === "" || this.password === "") {
        this.formIsValid = false;
        return;
      }
      const response = await fetch(
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBpYoTsCykAcvHdqyyzpiK2HqCL-_m42aU",
        {
          method: "POST",
          body: JSON.stringify({
            email: this.email,
            password: this.password,
            returnSecureToken: true,
          }),
        }
      );
      const responseData = await response.json();

      if (!response.ok) {
        console.log(responseData);
        const error = new Error(
          responseData.message || "Failed to authenticate"
        );
        this.loginStatus = responseData.message || "Failed to authenticate";
        throw error;
      } else {
        console.log(
          "State of isAuth in UserAuth before toggle: " + this.isAuth
        );
        this.toggleAuth();
        if (this.getAuthState() === true) {
          this.setUserId(responseData.localId);
          this.loginStatus =
            "Welcome, you are now authenticated. Your userID is: " +
            responseData.localId;
          localStorage.setItem("idToken", responseData.idToken);
          localStorage.setItem("userId", responseData.localId);
          this.$router.push("/");
        } else {
          this.loginStatus =
            responseData.message || "Failed to authenticate, please try again";
        }
      }
    },
  },
};
</script>
